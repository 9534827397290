import React, { useContext } from 'react';
import BlockRenderer from 'blocks/BlockRenderer';
import Layout from 'blocks/Layout';
import cx from 'classnames';
import AudioPlayerChip from 'components/AudioPlayerChip';
import Container from 'components/Container';
import AppContext from 'containers/AppContext';
import NODES from 'djedi-nodes/school';
import { NextPage } from 'next';
import DefaultErrorPage from 'next/error';
import Head from 'next/head';
import styles from 'partials/Index/Slug.module.css';
import { Market } from 'utils/constants';
import makeStringParam from 'utils/makeStringParam';

import { EnhancedPageContext } from '../../types/EnhancedPageContext';
import { AddendumDetail } from '../../types/Lesson';

export type ArticleProps = { data?: AddendumDetail };

const ArticlePage: NextPage<ArticleProps> = ({ data }) => {
  const { currentMarket } = useContext(AppContext);

  if (!data?.id) {
    return (
      <>
        <Head>
          <meta name="robots" content="noindex" />
        </Head>
        <DefaultErrorPage statusCode={404} />
      </>
    );
  }

  const firstItemIsShape =
    data?.body?.length > 0 && ['shape_container', 'swoosh'].includes(data.body[0].type);
  const color = firstItemIsShape
    ? data.body[0].value.color || data.body[0].value.from_color || undefined
    : undefined;

  return (
    <Layout title={data.title} description={data.description} NavProps={{ color: color }}>
      <div className={cx(currentMarket === Market.NO && styles.bookingBlock)}>
        <Container component="header">
          {data.audio && (
            <AudioPlayerChip
              title={React.cloneElement(NODES.LISTEN, { title: data.title })}
              audio={data.audio}
            />
          )}
        </Container>
        <BlockRenderer pageType="landing" body={data.body} />
      </div>
    </Layout>
  );
};

ArticlePage.getInitialProps = async function (ctx: EnhancedPageContext): Promise<ArticleProps> {
  const { api, query } = ctx;
  const { slug } = query;
  try {
    const data = await api.getLandingPageDetail(makeStringParam(slug));
    return { data };
  } catch (e) {
    return {};
  }
};

export default ArticlePage;
