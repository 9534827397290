import React, { SyntheticEvent } from 'react';
import { withCustomAudio } from 'react-soundplayer/addons';
import { Progress } from 'react-soundplayer/components';
import cx from 'classnames';

import { PageAudio } from '../../../types/Lesson';
import PauseSVG from '../../icons/pause_circle_outline.svg';
import PlaySVG from '../../icons/play_circle_outline.svg';
import styles from './AudioPlayerChip.module.css';

type SPA = {
  play: (s: Record<string, unknown>) => void;
  pause: () => void;
  _playlistIndex: Record<string, unknown>;
};

type PP = {
  trackTitle: string;
  currentTime: number;
  playing: boolean;
  soundCloudAudio: SPA;
};

const CustomPlayButton: React.FC<{
  playing: boolean;
  soundCloudAudio: SPA;
  onTogglePlay: (e: SyntheticEvent) => void;
}> = ({ playing, soundCloudAudio, onTogglePlay }) => {
  const onClick = React.useCallback(
    (e: SyntheticEvent) => {
      if (!playing) {
        soundCloudAudio &&
          soundCloudAudio.play({
            playlistIndex: soundCloudAudio._playlistIndex,
          });
      } else {
        soundCloudAudio && soundCloudAudio.pause();
      }

      onTogglePlay && onTogglePlay(e);
    },
    [onTogglePlay, playing, soundCloudAudio],
  );

  return (
    <button className={styles.playPauseButton} onClick={onClick}>
      {playing ? <PauseSVG /> : <PlaySVG />}
    </button>
  );
};

const createTimeStamp = (nums: number): string => {
  const s = nums % 60;
  const m = (nums - s) / 60;
  const fs = Math.round(s);

  return `${m}:${fs < 10 ? '0' : ''}${fs}`;
};

const SoundPlayer = withCustomAudio((props: PP) => {
  const { trackTitle, currentTime, ...rest } = props;
  const [hasPlayed, setHasPlayed] = React.useState(false);

  return (
    <div className={styles.root}>
      <div className={cx(styles.base, { [styles.baseStarted]: hasPlayed })}>
        <CustomPlayButton onTogglePlay={() => setHasPlayed(true)} {...rest} />
        {hasPlayed ? (
          <>
            <Progress
              currentTime={currentTime}
              {...rest}
              className={styles.timeline}
              innerClassName={styles.innerTimeline}
            />
            <span className={styles.timestamp}>{createTimeStamp(currentTime)}</span>
          </>
        ) : (
          <span className={styles.unplayed}>{trackTitle}</span>
        )}
      </div>
    </div>
  );
});

/**
 *
 * @param audio PageAudio object, containing at least url and type
 * @param title String or djedinode - To be displayed within the Pill
 * @deprecated Use AudioPlayer instead
 */
const AudioPlayerChip: React.FC<{ audio: PageAudio; title: string | React.ReactNode }> = ({
  audio,
  title,
}) => {
  return <SoundPlayer streamUrl={audio.url} trackTitle={title} preloadType="auto" />;
};
export default AudioPlayerChip;
